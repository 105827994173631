import React, { useState } from 'react'
import styled from 'styled-components'
import Totals from './Totals'
import Products from './Products'
import Stats from './Stats'
import {
  PageContent,
  SpinnerRect,
  SourcePicker,
  useSourcePickerState,
  DateRangePicker,
  useDateRangePickerState,
} from '../shared/elements'
import { useApiLoad } from '../shared/hooks'
import { toQuery } from '../../utils/helpers'

const Container = styled.div`
  .overview-filters {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    > * + * {
      margin-left: 16px;
    }
  }

  .h1 {
    margin-bottom: 14px;
    font-family: 'GothamRounded', sans-serif;
    font-size: 20px;
    font-weight: 400;
  }

  .h2 {
    margin-bottom: 14px;
    font-family: 'GothamRounded', sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
`

export default function Overview(props) {
  const sourcePickerState = useSourcePickerState()
  const { sourceUuid, sourceType, sourceContracts, setSourceContracts } =
    sourcePickerState

  const dateRangePickerState = useDateRangePickerState()
  const { startDate, defaultStartDate, endDate, defaultEndDate } =
    dateRangePickerState

  const [contracts, setContracts] = useState(null)
  const [publishers, setPublishers] = useState(null)
  const [products, setProducts] = useState(null)
  const [stats, setStats] = useState(null)
  const [conversions, setConversions] = useState(null)
  const [currentStats, setCurrentStats] = useState(null)
  const [previousStats, setPreviousStats] = useState(null)

  const overviewQuery = toQuery({
    startDate: (startDate || defaultStartDate).toISOString(),
    endDate: (endDate || defaultEndDate).toISOString(),
    filterUuid: sourceUuid,
    filterType: sourceType,
    productOrder: 'quantity',
    productLimit: 20,
  })
  useApiLoad(
    `/advertiser/overview?${overviewQuery}`,
    publishers === null,
    (response) => {
      setContracts(response?.data?.contracts)
      setPublishers(response?.data?.publishers)
      setSourceContracts(response?.data?.searchContracts)
      setProducts(response?.data?.conversionItemStats)
      setStats(response?.data?.stats)
      setConversions(response?.data?.conversions)
      setCurrentStats(response?.data?.overviewCurrentStats)
      setPreviousStats(response?.data?.overviewPreviousStats)
    }
  )

  const reloadData = () => {
    setContracts(null)
    setPublishers(null)
    setProducts(null)
    setStats(null)
  }

  const loading =
    contracts === null ||
    publishers === null ||
    sourceContracts === null ||
    products === null ||
    stats === null ||
    conversions === null
  if (loading) {
    return (
      <PageContent>
        <div className="text-center">
          <SpinnerRect />
        </div>
      </PageContent>
    )
  }

  return (
    <Container>
      <PageContent>
        <div className="overview-filters">
          <SourcePicker state={sourcePickerState} onChange={reloadData} />
          <DateRangePicker state={dateRangePickerState} onChange={reloadData} />
        </div>

        <div className="h1">Overview</div>

        <div className="row mb-4">
          <div className="col-12">
            <Totals
              {...{
                currentStats,
                previousStats,
              }}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="h2">Top products</div>

          <div className="col-12 mb-4">
            <div className="panel px-4">
              <Products contracts={contracts} products={products} />
            </div>
          </div>
        </div>

        {/*<div className="row mb-4">
          <div className="h2">Top sources</div>

          {publishers.map((publisher) => (
            <div key={publisher.uuid} className="col-lg-6 col-xxl-4 mb-4">
              <Partner
                publisher={publisher}
                stats={findStats(publisher)}
                conversions={findConversions(publisher)}
              />
            </div>
          ))}
          {newPublishers?.length > 0 && (
            <div className="col-lg-6 col-xxl-4 mb-4">
              <PartnerSuggestions publishers={newPublishers} />
            </div>
          )}
        </div>*/}

        {/*
        <div className="mb-4 d-none">
          <ConnectIntegrations />
        </div>

        <div className="mb-4 d-none">
          <CampaignTable
            {...{
              publishers,
              findStats,
              findConversions,
            }}
          />
        </div>
        */}

        <div className="mb-4">
          <div className="h2">Trends</div>

          <Stats
            {...{
              startDate,
              endDate,
              defaultStartDate,
              defaultEndDate,
              currentStats,
              previousStats,
            }}
          />
        </div>
      </PageContent>
    </Container>
  )
}
